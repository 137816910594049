import { mutate } from 'swr';
import { fetchApiHub } from 'frontastic';

export const createSession = async (value: number, currency: string, returnUrl: string) => {
  const payload = {
    amount: {
      value: value,
      currency: currency,
    },
    returnUrl,
  };
  console.log('kiem tra createSession 0');
  const res = await fetchApiHub('/action/payment/createSession', { method: 'POST' }, payload);
  console.log('kiem tra createSession 1');
  await mutate('/action/payment/createSession', res);
  console.log('kiem tra createSession 2');
  return res;
};
