import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export type Review = {
  name: string;
  subject: string;
  description: string;
  rating: number;
  date?: string;
};

export const getReview = () => {
  // return useSWR('/action/review/getReview', fetchApiHub, revalidateOptions);
  return useSWR('/action/wishlist/getWishlist', fetchApiHub, revalidateOptions);
};

export const addToReview = async (payload: Review) => {
  const res = await fetchApiHub('/action/review/addToReview', { method: 'POST' }, { payload });
  mutate('/action/review/getReview', res, { revalidate: false });
};
